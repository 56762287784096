/**
 * 单页面配置
 */

module.exports = {
    insidepage: {
        entry: 'src/insidepage/main.js',
        title: '内页',
        template: 'public/index.html',
        filename: 'page.html',
        chunks: ['chunk-vendors', 'chunk-common', 'insidepage']
    },
}   